// src/components/ChainDropdown.js
import React, { useState, useEffect, useRef } from "react";
import useStore from "../store";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";
import CheckIcon from "../assets/Icons/Check.svg";
import CloseEyeIcon from "../assets/Icons/close-eye-1.svg";
import SpanIcon from "../assets/Icons/spam-1.svg";

const filters = [
  { name: "Hide tokens < 1USD", key: "hideLowBalance", icon: CloseEyeIcon },
  { name: "Hide spam tokens", key: "hideSpam", icon: SpanIcon },
];

export const MoreTokenFilter = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { tokenFilters, setTokenFilters } = useStore();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleFilter = (key) => {
    setTokenFilters(
      tokenFilters.includes(key)
        ? tokenFilters.filter((name) => name !== key)
        : [...tokenFilters, key]
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="px-4 py-2 bg-transparent text-white rounded-3xl border-[1px] border-[#25292D] flex flex-row items-center gap-2"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div>More</div>
        <div>
          <img
            src={ToggleIcon}
            alt="Check Icon"
            className="invert h-[17px] w-[17px]"
          />
        </div>
      </button>
      {dropdownOpen && (
        <div className="absolute mt-2 w-60 bg-[#141719] border border-[#25292D] rounded-lg shadow-lg">
          <div className="max-h-60 overflow-y-auto no-scrollbar">
            {filters.map((filter) => (
              <div
                key={filter.key.toLowerCase()}
                onClick={() => toggleFilter(filter.key.toLowerCase())}
                className="flex items-center justify-start gap-2 px-2 py-2 hover:bg-[#1A1D20] rounded-md cursor-pointer"
              >
                <div className="w-[20px] h-[20px] border-[1.5px] border-white rounded-md">
                  {tokenFilters.includes(filter.key.toLowerCase()) && (
                    <img src={CheckIcon} alt="Check Icon" className="invert" />
                  )}
                </div>
                <div className="flex">
                  {/* {filter.icon && (
                    <img
                      src={filter.icon}
                      alt="Icon"
                      className="mr-2 w-6 h-6"
                    />
                  )} */}
                  <span>{filter.name}</span>
                </div>

                {/* <div className="flex">
              
             </div> */}

                {/* <button
                  className={`${
                    tokenFilters.includes(filter.key.toLowerCase())
                      ? "block"
                      : "hidden"
                  }`}
                >
                  <img src={CheckIcon} alt="Check Icon" className="invert" />
                </button> */}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
