import React, { useEffect, useState } from "react";
import blockies from 'blockies';

const Blockie = ({ address, size = 8, scale = 10 }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    const generateAvatar = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const blockie = blockies({
        address,
        size,
        scale,
      });

      canvas.width = size * scale;
      canvas.height = size * scale;
      ctx.drawImage(blockie, 0, 0, canvas.width, canvas.height);

      setAvatarUrl(canvas.toDataURL());
    };

    generateAvatar();
  }, [address, size, scale]);

  return (
    <img
      src={avatarUrl}
      alt="Blockies Avatar"
      className="rounded-md"
      style={{ width: `${size * scale}px`, height: `${size * scale}px` }}
    />
  );
};

export default Blockie;
