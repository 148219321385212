import React from "react";
import { DownloadAppButton } from "./DownloadAppPopUp";
import SafleLogo from "../assets/SafleLens.png";

export const NavbarHome = () => {

return (
    <div className="flex justify-between p-4 bg-[#101113] text-white">
    <div className="pl-[8rem]">
      <img src={SafleLogo} alt="Safle Logo" className="w-[9rem] h-[3rem] mr-2" />
    </div>
    <div className="pr-[8rem]">
    <DownloadAppButton />
    </div>
  </div>
)
}   