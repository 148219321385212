import React from "react";
import { TxnTable } from "./TxnTable";

export const TxnGroup = ({ txnGroup }) => {
  return (
    <div className="flex flex-col">
      <div className="pt-2 px-6">
        {new Date(txnGroup?.date * 1000).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </div>
      <TxnTable txns={txnGroup?.transactions} />
    </div>
  );
};
