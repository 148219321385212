import React, { useState, useMemo } from "react";
import useStore from "../store";
import { currencies } from "../utils/currencies";
import CoinIcon from "../assets/coin.svg";
import ArrowUpIcon from "../assets/Icons/up-arrow.svg";
import ArrowDownIcon from "../assets/Icons/down-arrow.svg";
import SpamIcon from "../assets/Icons/SPAM-ticker.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import AIICON2 from "../assets/Icons/AI-icon-2.svg";

export const AssetTable = ({ assets }) => {
  const { conversionRate, activeCurrency, chains, setIsAIActive, setChatContext, setActiveAIToken} = useStore();
  // State to manage sort direction for each column
  const [sortDirection, setSortDirection] = useState({
    balance: null,
    price: null,
    holdings: null,
    '24hChange': null,
  });

  const handleAIClick = (asset) => {
    setIsAIActive(true);
    setChatContext("token");
    setActiveAIToken(asset);
  }

  // Function to handle sorting
  const handleSort = (column) => {
    setSortDirection((prev) => {
      const direction = prev[column] === "asc" ? "desc" : "asc";
      return {
        ...prev,
        [column]: direction
      };
    });
  };

  const sortedAssets = useMemo(() => {
    if (!Object.values(sortDirection).some(dir => dir !== null)) return assets;

    const activeSortColumn = Object.keys(sortDirection).find(column => sortDirection[column] !== null);
    const direction = sortDirection[activeSortColumn];

    return [...assets].sort((a, b) => {
      let aValue, bValue;

      switch (activeSortColumn) {
        case 'balance':
          aValue = a.balance;
          bValue = b.balance;
          break;
        case 'price':
          aValue = a.quote * conversionRate;
          bValue = b.quote * conversionRate;
          break;
        case 'holdings':
          aValue = a.quote_rate * conversionRate;
          bValue = b.quote_rate * conversionRate;
          break;
        case '24hChange':
          aValue = a.quote_pct_change_24h;
          bValue = b.quote_pct_change_24h;
          break;
        default:
          return 0;
      }

      if (direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [assets, sortDirection, conversionRate]);

  // Calculate portfolio percentages
  const totalPortfolioValue = assets?.reduce(
    (total, asset) => total + asset.quote,
    0
  );
  function formatAssetBalance(balance) {
    const fractionDigits = Math.min(
      6,
      balance.toString().split(".")[1]?.length || 0
    );
    
    return balance.toLocaleString('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits
    });
  }

  // eslint-disable-next-line no-unused-vars
  function formatNumber(value, maxFractionDigits = 2) {
    const fractionDigits = Math.min(
      maxFractionDigits,
      value.toString().split(".")[1]?.length || 0
    );
  
    return value.toLocaleString('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits
    });
  }

  return (
    <div className="container mx-auto px-4 border-separate overflow-clip">
      <table className="w-full table-fixed">
        <thead className="sticky top-[55px]" style={{ zIndex: 10}}>
          <tr className="bg-[#141719] text-[#6D7072] text-base text-left">
            <th className="py-2 px-4 font-normal items-center text-ellipsis">
              Token
            </th>
            <th className="py-2 px-4 font-normal w-1/6 items-center">
              <div className="flex items-center">
                Balance
                <div className="flex flex-col ml-2">
                  <button onClick={() => handleSort("balance")}>
                    <img
                      src={ArrowUpIcon}
                      alt="Sort Ascending"
                      className={`h-2 w-2 ${sortDirection.balance === 'asc' ? 'text-green-500' : ''}`}
                    />
                  </button>
                  <button onClick={() => handleSort("balance")}>
                    <img
                      src={ArrowDownIcon}
                      alt="Sort Descending"
                      className={`h-2 w-2 ${sortDirection.balance === 'desc' ? 'text-red-500' : ''}`}
                    />
                  </button>
                </div>
              </div>
            </th>
            <th className="py-2 px-4 font-normal w-1/6 items-center">
              <div className="flex items-center">
                Price
                <div className="flex flex-col ml-2">
                  <button onClick={() => handleSort("price")}>
                    <img
                      src={ArrowUpIcon}
                      alt="Sort Ascending"
                      className={`h-2 w-2 ${sortDirection.price === 'asc' ? 'text-green-500' : ''}`}
                    />
                  </button>
                  <button onClick={() => handleSort("price")}>
                    <img
                      src={ArrowDownIcon}
                      alt="Sort Descending"
                      className={`h-2 w-2 ${sortDirection.price === 'desc' ? 'text-red-500' : ''}`}
                    />
                  </button>
                </div>
              </div>
            </th>
            <th className="py-2 px-4 font-normal w-1/6 items-center">
              Holdings in {activeCurrency?.toUpperCase()}
              <div className="flex flex-col ml-2">
                <button onClick={() => handleSort("holdings")}>
                  <img
                    src={ArrowUpIcon}
                    alt="Sort Ascending"
                    className={`h-2 w-2 ${sortDirection.holdings === 'asc' ? 'text-green-500' : ''}`}
                  />
                </button>
                <button onClick={() => handleSort("holdings")}>
                  <img
                    src={ArrowDownIcon}
                    alt="Sort Descending"
                    className={`h-2 w-2 ${sortDirection.holdings === 'desc' ? 'text-red-500' : ''}`}
                  />
                </button>
              </div>
            </th>
            <th className="py-2 px-4 font-normal w-1/6 items-center">
              Portfolio %
            </th>
            <th className="py-2 px-4 font-normal w-1/6 items-center">
              <div className="flex items-center">
                24h Change
                <div className="flex flex-col ml-2">
                  <button onClick={() => handleSort("24hChange")}>
                    <img
                      src={ArrowUpIcon}
                      alt="Sort Ascending"
                      className={`h-2 w-2 ${sortDirection['24hChange'] === 'asc' ? 'text-green-500' : ''}`}
                    />
                  </button>
                  <button onClick={() => handleSort("24hChange")}>
                    <img
                      src={ArrowDownIcon}
                      alt="Sort Descending"
                      className={`h-2 w-2 ${sortDirection['24hChange'] === 'desc' ? 'text-red-500' : ''}`}
                    />
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-[#1A1D20]">
          {sortedAssets?.map((asset, index) => (
            <tr key={index}>
              <td className="py-4 px-4 flex flex-row justify-between items-center text-ellipsis text-base">
                <div className="flex flex-row">
                <div className="flex flex-row items-center relative">
                  <img
                    src={asset.logo_url}
                    alt={asset.contract_name || asset.contract_ticker_symbol}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop if fallback image fails too
                      e.target.src = CoinIcon;
                    }}
                    className="w-6 h-6"
                  />
                  {asset.verified ? (
                      <img
                        src={chains?.find(
                          (chain) => chain?.name?.toLowerCase() === asset?.chainName
                        )?.logo_url}
                        alt="Chain logo"
                        className="rounded-full w-[15px] h-[15px] relative bottom-[-5px] right-[10px] border-2 border-[#6D7072] bg-white"
                      />
                    ) : (
                      <img
                        src={SpamIcon}
                        alt="Spam Icon"
                        className="rounded-full w-[15px] h-[15px] relative bottom-[-5px] right-[10px]"
                      />
                    )}
                </div>
                <div className="flex flex-col">
                  <div className="text-base font-medium flex items-center">
                    {asset.contract_ticker_symbol.length > 15 
                      ? `${asset.contract_ticker_symbol.substring(0, 17)}...` 
                      : asset.contract_ticker_symbol}
                  </div>
                  <div className="text-[13px] text-[#6D7072]">
                    {asset.contract_name.length > 15 
                    ? `${asset.contract_name.substring(0, 17)}...`
                    : asset.contract_name}
                  </div>
                </div>
                </div>
                <div>
                  <img 
                  src={AIICON2} 
                  alt="AI Icon"
                  className="cursor-pointer"
                  onClick={() => handleAIClick(asset)}
                  />
                </div>
              </td>
              <td className="py-4 px-4 w-1/6">
              {formatAssetBalance(asset.balance)}{" "}
              {asset.contract_ticker_symbol.length > 5
                ? `${asset.contract_ticker_symbol.substring(0, 5)}...`
                : asset.contract_ticker_symbol}
              </td>
              <td className="py-4 px-4 w-1/6">
                {
                  currencies?.find(
                    (currency) =>
                      currency["Currency Code"].toLowerCase() === activeCurrency
                  )?.["Currency Symbol"]
                }
                {(asset.quote * conversionRate).toFixed(2)}
              </td>
              <td className="py-4 px-4 w-1/6">
                {
                  currencies?.find(
                    (currency) =>
                      currency["Currency Code"].toLowerCase() === activeCurrency
                  )?.["Currency Symbol"]
                }
                {(asset.quote_rate * conversionRate).toFixed(2)}
              </td>
              <td className="py-4 px-4 w-1/6">
                {((asset.quote / totalPortfolioValue) * 100).toFixed(2)}%
              </td>
              <td className="py-4 px-4 w-1/6">
                {asset.quote_pct_change_24h >= 0 ? (
                  <span className="text-green-500 flex items-center">
                    <FontAwesomeIcon icon={faCaretUp} className="p-[0.5rem]" />
                    {Math.abs(asset.quote_pct_change_24h).toFixed(2)}%
                  </span>
                ) : (
                  <span className="text-red-500 flex items-center">
                    <FontAwesomeIcon icon={faCaretDown} className="p-[0.5rem]" />
                    {Math.abs(asset.quote_pct_change_24h).toFixed(2)}%
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
