import React, { useState, useEffect } from "react";
import Blockie from "../utils/blockies";
import useStore from "../store";
import { CurrencyDropDown } from "./CurrencyDropDown";
import { currencies } from "../utils/currencies";
import { ChainCardGroup } from "./ChainCardGroup";
import { RefreshGroup } from "./RefreshGroup";

export const ProfileInfo = ({ showChainWorth, screenName }) => {
  const {
    user,
    chainWorth,
    conversionRate,
    activeCurrency,
  } = useStore();
  const [totalWorth, setTotalWorth] = useState(0);
  const [activeCurrencySymbol, setActiveCurrencySymbol] = useState("$");
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        if (screenName === "token") {
          setShowRefresh(true);
        }
        if (screenName === "nft") {
          setShowRefresh(false);
        }
        if (screenName === "txn") {
          setShowRefresh(false);
        }
        //     setCardsPerRow(2);
        //   } else if (window.innerWidth < 768) {
        //     setCardsPerRow(2);
        //   } else if (window.innerWidth < 1024) {
        //     setCardsPerRow(3);
        //   } else if (window.innerWidth < 1280) {
        //     setCardsPerRow(4);
      } else {
        if (screenName === "token") {
          setShowRefresh(true);
        }
        if (screenName === "nft") {
          setShowRefresh(true);
        }
        if (screenName === "txn") {
          setShowRefresh(false);
        }
        // setCardsPerRow(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chainWorth && chainWorth?.length > 0) {
      let totalvalue = 0;
      chainWorth?.forEach((item) => {
        totalvalue += item?.net_asset_worth;
      });
      if (totalvalue) {
        setTotalWorth(totalvalue * conversionRate);
      }
    }
    if (activeCurrency === "usd") {
      setActiveCurrencySymbol("$");
    } else {
      const activeCurr = currencies?.find(
        (currency) => currency["Currency Code"].toLowerCase() === activeCurrency
      );
      setActiveCurrencySymbol(activeCurr?.["Currency Symbol"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainWorth, conversionRate]);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col md:flex-row justify-between text-white mx-4 w-full h-[150px] p-4">
        <div className="flex flex-row items-center">
          <div className="h-[70px] w-[70px] md:h-[120px] md:w-[120px] bg-[#141719] flex justify-center items-center rounded-[20px] border-[1px] border-[#1A1D20] p-4 mr-4 ">
            {user?.address && <Blockie
              address={
                user?.address
              }
            />}
          </div>
          <div className="flex flex-col">
            <div className="w-full overflow-hidden text-ellipsis text-[25px] mb-[16px]">
              {user?.safleId || user?.ENS || user?.address}
            </div>
            <div className="text-[13px] text-[#6D7072]">Portfolio Value</div>
            <div className="flex flex-row items-center">
              <div className="mr-4 text-[20px]">
                {activeCurrencySymbol}
                {totalWorth
                ? totalWorth.toLocaleString('en-IN', {
                    minimumFractionDigits: totalWorth % 1 === 0 ? 0 : Math.min(2, totalWorth?.toString()?.split(".")[1]?.length)
                  })
                : '0'}
              </div>
              <CurrencyDropDown />
            </div>
          </div>
        </div>
        {showRefresh && (
          <div className="flex flex-col-reverse">
            <RefreshGroup
              showChainWorth={showChainWorth}
              handleExpandClick={handleExpandClick}
            />
          </div>
        )}
      </div>
      {showChainWorth && (
        <div className="container mx-auto p-4">
          <ChainCardGroup cards={chainWorth} isExpanded={isExpanded} />
        </div>
      )}
    </div>
  );
};
