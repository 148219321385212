import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import { checkUser } from "../utils/helper";
import SearchIcon from "../assets/Icons/Search.svg";
import RightArrow from "../assets/Icons/Arrow.svg";
import DashboardImage from "../assets/Icons/Tokens-SafleID.svg";
import { NavbarHome } from "../components/NavbarHome";

export const HomeScreen = ({ children }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { setUser } = useStore();
  const [error, setError] = useState(null);

  // Handle search input change
  const handleInputChange = (e) => {
    setError(null);
    setSearchQuery(e.target.value);
  };

  // Handle Enter key press
  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      if (searchQuery) {
        const user = await checkUser({ walletId: searchQuery });
        if (typeof user !== "string") {
          setUser(user);
          navigate(`/${searchQuery}`);
        } else {
          setError(user);
        }
      }
    }
  };

    // Handle click on the arrow icon
    const handleIconClick = async () => {
      if (searchQuery) {
        const user = await checkUser({ walletId: searchQuery });
        if (typeof user !== "string") {
          setUser(user);
          navigate(`/${searchQuery}`);
        } else {
          setError(user);
        }
      }
    };

  return (
    <div className="flex text-white flex-col w-full justify-center bg-[#101113] min-h-screen overflow-hidden">
      <NavbarHome />
      <div className="flex flex-col items-center divide-y-[1px] divide-[#25292D] mb-[16rem]">
        <div className="p-4 text-center">
        <div className="p-1 font-bold text-3xl text-[#BBBDBE]">
            The All-In-One <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#FF7777] to-[#0669F8]">Crypto Wallet</span>
          </div>
          <div className="p-2 text-[white] text-base border-none whitespace-pre-wrap w-full max-w-[550px]">
            The ultimate gateway to the decentralized world of crypto & Web3, in a simple & secure cryptocurrency wallet for all your needs!
          </div>
          <div className="flex items-center justify-center mt-4">
            <div className="relative md:w-[400px]">
              <img
                src={SearchIcon}
                alt="Search Icon"
                className="absolute left-5 top-1/2 transform -translate-y-1/2 w-5 h-5 invert hidden md:block"
              />
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                placeholder="Search any SafleID or EVM/ENS address"
                className="pl-10 pr-4 px-4 py-2 bg-[#101113] text-base rounded-full border-[1px] border-[#1A1D20] md:w-[380px]"
              />
              <img
                src={RightArrow}
                alt="Right Arrow"
                className="absolute right-4 top-1/2 transform -translate-y-1/2  cursor-pointer rounded-full bg-[#0669F8] p-1"
                onClick={handleIconClick}
              />
            </div>
          </div>
          
          <div className="items-center justify-center h-[1rem] pt-1">
           
          {error && <div className="text-red-500">{error}</div>}
           </div>
        </div>
        <div className="flex items-center justify-center mt-4">
          <img src={DashboardImage} alt="Dashboard" />
        </div>
        {/* {error && <div>{error}</div>} */}
      </div>
    </div>
  );
};
