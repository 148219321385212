import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../store";
import { checkUser } from "../utils/helper";
import SearchIcon from "../assets/Icons/Search.svg";
import { ToastContainer, toast } from "react-toastify";
import { DownloadAppButton } from "./DownloadAppPopUp";
import "react-toastify/dist/ReactToastify.css";

export const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { setUser, sideBar, setSideBar } = useStore();

  const showErrorToast = () => {
    toast.error("Address not found!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      //   theme: "dark",
    });
  };

  // Determine the title based on the current route
  const getTitle = (pathname) => {
    switch (pathname) {
      case "/":
        return "Home";
      case "/featureRequest":
        return "Request a Feature";
      default:
        if (pathname.includes("/tokens")) return "Tokens";
        if (pathname.includes("/nfts")) return "NFTs";
        if (pathname.includes("/transactions")) return "Transactions";
        return "Dashboard";
    }
  };
  // Handle search input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle Enter key press
  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      if (searchQuery) {
        const user = await checkUser({ walletId: searchQuery });
        if (typeof user !== "string") {
          setUser(user);
          navigate(`/${searchQuery}`);
        } else {
          //   setError(user);
          showErrorToast();
        }
      }
    }
  };

  const toggleSidebar = () => {
    setSideBar(!sideBar);
  };

  return (
    <div className="flex justify-between items-center p-4 bg-[#101113] text-white border-[1px] border-[#1A1D20]">
      <div className="flex items-center">
        <button
          className="block md:hidden text-2xl mr-4"
          onClick={toggleSidebar}
        >
          &#9776; {/* Hamburger menu icon */}
        </button>
        <h1 className="text-lg">{getTitle(location.pathname)}</h1>
      </div>
      <div className="relative md:w-[340px]">
        <div className="relative">
          <img
            src={SearchIcon}
            alt="Search Icon"
            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 invert"
          />
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Search SafleID or EVM address or ENS"
            className="pl-10 pr-4 px-4 py-2 bg-[#101113] text-base rounded-[20px] border-[1px] border-[#1A1D20] hidden md:block md:w-[340px] placeholder-custom"
          />
        </div>
      </div>

      <style>
        {`
            .Toastify {
                position: absolute;
            }
            .Toastify__toast {
                
                top: -15px !important
            }
            `}
      </style>
      <ToastContainer />
      <DownloadAppButton />
    </div>
  );
};
