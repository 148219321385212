import React, { useEffect, useState } from "react";
import {
  ProfileInfo,
  NetworkDropdown,
  TxnGroup,
  TxnTypeDropdown,
  CalendarDropdown,
  RefreshGroup,
} from "../components";
import { TxnOnChains } from "../api/userApi";
import useStore from "../store";

export const TxnScreen = ({ children }) => {
  const {
    user,
    chains,
    activeChains,
    transactionType,
    transactions,
    dateFilter,
    setTransactions,
    setTxnRefreshTime
  } = useStore();
  const [filteredTxnList, setFilteredTxnList] = useState([]);
  const [showMobileTxnDropdown, setShowMobileTxnDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMobileCalendarDropdown, setShowMobileCalendarDropdown] =
    useState(false);

  useEffect(() => {
    async function fetchingNFTs() {
      if (chains && chains?.length > 0) {
        setLoading(true);
        const TxnList = await Promise.all(
          chains.map(async (chain) => {
            return await TxnOnChains(chain?.name?.toLowerCase(), user.address);
          })
        );

        setTransactions(TxnList);
        const timeStamp = Date.now();
        setTxnRefreshTime(timeStamp);
        setLoading(false);
      }
    }
    fetchingNFTs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains]);

  useEffect(() => {
    let allTXNs = transactions
      ?.filter((chain) => activeChains.includes(chain?.chain?.toLowerCase()))
      ?.flatMap(({ transactions, chain }) =>
        transactions.map((transaction) => ({ ...transaction, chain }))
      )
      ?.filter((item) =>
        dateFilter?.startDate === 0
          ? item?.date * 1000 <= dateFilter?.endDate?.getTime()
          : item?.date * 1000 >= dateFilter?.startDate?.getTime() &&
            item?.date * 1000 <= dateFilter?.endDate?.getTime() + 86400000
      );

    if (transactionType !== "all") {
      allTXNs = allTXNs?.filter(
        (transaction) => transaction?.type === transactionType
      );
    }

    const normalizeDate = (timestamp) =>
      new Date(timestamp * 1000).toISOString().split("T")[0];

    allTXNs.forEach((transaction) => {
      transaction.normalizedDate = normalizeDate(transaction.date);
    });

    let groupedTransactions = allTXNs.reduce((acc, transaction) => {
      let date = transaction.normalizedDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(transaction);
      return acc;
    }, {});

    let result = Object.keys(groupedTransactions)
      .sort((a, b) => new Date(b) - new Date(a)) // Sort groups by date in descending order
      .map((date) => ({
        date: new Date(date).getTime() / 1000, // Convert back to timestamp
        transactions: groupedTransactions[date].sort((a, b) => b.date - a.date), // Sort transactions by date in descending order
      }));
    setFilteredTxnList(result);
  }, [transactions, activeChains, transactionType, dateFilter]);

  return (
    <div className="flex flex-col text-white w-full divide-y-[1px] divide-[#1A1D20]">
      <ProfileInfo screenName={"txn"} />
      <div className="flex flex-col relative">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center transition-all duration-300 sticky top-[-17px] bg-[#101113]" style={{ zIndex: 100 }}>
          <div className="flex flex-row justify-between mx-4 my-2 md:justify-start md:gap-4  p-4">
            <div className="flex flex-row">
              <NetworkDropdown />
            </div>
            <div className="flex flex-row">
                {/* Desktop View Dropdown */}
                <div className="hidden md:block">
                <TxnTypeDropdown />
              </div>
              {/* Mobile View Dropdown */}
              <div className="block md:hidden relative pt-[0.5rem]">
                <button
                  onClick={() =>
                    setShowMobileTxnDropdown(!showMobileTxnDropdown)
                  }
                  className="text-white focus:outline-none"
                >
                  &#x22EE; {/* Vertical dots icon */}
                </button>
                {showMobileTxnDropdown && (
                  <div className="absolute right-0 mt-2 bg-[#1A1D20] rounded-lg shadow-lg z-10">
                    <TxnTypeDropdown />
                  </div>
                )}
              </div>
            </div>
            <div>
              {/* Desktop View CalendarDropdown */}
              <div className="hidden md:block">
                <CalendarDropdown />
              </div>
              {/* Mobile View CalendarDropdown */}
              <div className="block md:hidden relative pr-[2rem] pt-[0.5rem]">
                <button
                  onClick={() =>
                    setShowMobileCalendarDropdown(!showMobileCalendarDropdown)
                  }
                  className="text-white focus:outline-none invert border-[#25292D]"
                >
                  &#x1F4C5; {/* Calendar icon */}
                </button>
                {showMobileCalendarDropdown && (
                  <div className="absolute right-0 mt-2 bg-[#1A1D20] rounded-lg shadow-lg z-10">
                    <CalendarDropdown />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <RefreshGroup />
          </div>
        </div>

        {loading ? (
          <div className="w-full flex flex-row justify-center">
            <div className=" border-t-4 border-blue-500 rounded-full w-10 h-10 animate-spin">
              {/* <div className=" w-5 h-5 bg-blue-500 rounded-tr-sm"></div> */}
            </div>
          </div>
        ) : (
          <>
            <div className="hidden md:block">
              <div className="flex flex-col">
                {filteredTxnList &&
                  filteredTxnList?.map((dayTxn) => {
                    return <TxnGroup key={dayTxn.date} txnGroup={dayTxn} />;
                  })}
              </div>
            </div>
            <div className="block relative md:hidden">
              <div className="absolute top-1 right-1">
                <RefreshGroup />
              </div>
              <div className="flex flex-col text-sm text-[#6D7072] sm:text-base sm:p-4 md:text-lg md:p-6">
                {filteredTxnList &&
                  filteredTxnList.map((dayTxn) => {
                    return <TxnGroup key={dayTxn.date} txnGroup={dayTxn} />;
                  })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
