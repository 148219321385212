// src/App.js

import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { DashboardLayout } from "./components";
import {
  HomeScreen,
  TokensScreen,
  NFTsScreen,
  TxnScreen,
  WalletNotFound,
} from "./pages";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            // <DashboardLayout>
              <HomeScreen />
            // </DashboardLayout>
          }
        />
        <Route path="/:walletId">
          <Route index element={<Navigate to="tokens" replace />} />
          <Route
            path="tokens"
            element={
              <DashboardLayout>
                <TokensScreen />
              </DashboardLayout>
            }
          />
          <Route
            path="nfts"
            element={
              <DashboardLayout>
                <NFTsScreen />
              </DashboardLayout>
            }
          />
          <Route
            path="transactions"
            element={
              <DashboardLayout>
                <TxnScreen />
              </DashboardLayout>
            }
          />
          <Route
            path="notFound"
            element={
              <DashboardLayout>
                <WalletNotFound />
              </DashboardLayout>
            }
          />
        </Route>
        {/* <Route
          path="notFound"
          element={
            <DashboardLayout>
              <div>404 Not Found</div>
            </DashboardLayout>
          }
        /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
