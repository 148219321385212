import { create } from "zustand";
import { persist } from "zustand/middleware";
import { addDays } from "date-fns";

// Create the store
const useStore = create(
  persist(
    (set) => ({
      user: { address: "", safleId: "", ENS: "" },
      sideBar: false,
      chains: [],
      chainWorth: [],
      nftCollection: [],
      transactions: [],
      activeChains: [],
      tokenFilters: [],
      transactionType: "all",
      refresh: 0,
      tokenRefreshTime: 0,
      nftRefreshTime: 0,
      txnRefreshTime: 0,
      activeCurrency: "usd",
      conversionRate: 1,
      dateFilter: {
        startDate: 0,
        endDate: addDays(new Date(), 0),
        key: "selection",
      },
      isAIActive: false,
      chatContext: "",
      activeAIToken: null,
      setUser: (user) => set({ user }),
      setSideBar: (sideBar) => set({ sideBar }),
      setChains: (chains) => set({ chains }),
      setChainWorth: (chainWorth) => set({ chainWorth }),
      setNftCollection: (nftCollection) => set({ nftCollection }),
      setTransactions: (transactions) => set({ transactions }),
      setActiveChains: (activeChains) => set({ activeChains }),
      setTokenFilters: (tokenFilters) => set({ tokenFilters }),
      setTransactionType: (transactionType) => set({ transactionType }),
      setRefresh: (refresh) => set({ refresh }),
      setTokenRefreshTime: (tokenRefreshTime) => set({ tokenRefreshTime }),
      setNftRefreshTime: (nftRefreshTime) => set({ nftRefreshTime }),
      setTxnRefreshTime: (txnRefreshTime) => set({ txnRefreshTime }),
      setActiveCurrency: (activeCurrency) => set({ activeCurrency }),
      setConversionRate: (conversionRate) => set({ conversionRate }),
      setDateFilter: (dateFilter) => set({ dateFilter }),
      setIsAIActive: (isAIActive) => set({ isAIActive }),
      setChatContext: (chatContext) => set({ chatContext }),
      setActiveAIToken: (activeAIToken) => set({ activeAIToken }),
    }),
    {
      name: "my-storage", // name of the item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
      partialize: (state) => ({
        // user: state.user,
        activeCurrency: state.activeCurrency,
        conversionRate: state.conversionRate,
      }),
    }
  )
);

export default useStore;
