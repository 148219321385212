export const currencies = [
  {
    "Currency Code": "AED",
    "Currency Name": "United Arab Emirates Dirham",
    Region: "UNITED ARAB EMIRATES",
    "Currency Symbol": "د.إ",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
  },
  {
    "Currency Code": "AFN",
    "Currency Name": "Afghani",
    Region: "AFGHANISTAN",
    "Currency Symbol": "؋",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
  },
  {
    "Currency Code": "ALL",
    "Currency Name": "Albanian Lek",
    Region: "ALBANIA",
    "Currency Symbol": "L",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
  },
  {
    "Currency Code": "AMD",
    "Currency Name": "Armenian Dram",
    Region: "ARMENIA",
    "Currency Symbol": "֏",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
  },
  {
    "Currency Code": "ANG",
    "Currency Name": "Netherlands Antillean Guilder",
    Region: "CURACAO",
    "Currency Symbol": "ƒ",
  },
  {
    "Currency Code": "AOA",
    "Currency Name": "Angolan Kwanza",
    Region: "ANGOLA",
    "Currency Symbol": "Kz",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
  },
  {
    "Currency Code": "ARS",
    "Currency Name": "Argentine Peso",
    Region: "ARGENTINA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
  },
  {
    "Currency Code": "AUD",
    "Currency Name": "Australian Dollar",
    Region: "AUSTRALIA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
  },
  {
    "Currency Code": "AWG",
    "Currency Name": "Aruban Florin",
    Region: "ARUBA",
    "Currency Symbol": "ƒ",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
  },
  {
    "Currency Code": "AZN",
    "Currency Name": "Azerbaijanian Manat",
    Region: "AZERBAIJAN",
    "Currency Symbol": "₼",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
  },
  {
    "Currency Code": "BAM",
    "Currency Name": "Convertible Mark",
    Region: "BOSNIA AND HERZEGOVINA",
    "Currency Symbol": "KM",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
  },
  {
    "Currency Code": "BBD",
    "Currency Name": "Barbados Dollar",
    Region: "BARBADOS",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
  },
  {
    "Currency Code": "BDT",
    "Currency Name": "Bangladeshi Taka",
    Region: "BANGLADESH",
    "Currency Symbol": "৳",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
  },
  {
    "Currency Code": "BGN",
    "Currency Name": "Bulgarian Lev",
    Region: "BULGARIA",
    "Currency Symbol": "лв",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
  },
  {
    "Currency Code": "BHD",
    "Currency Name": "Bahraini Dinar",
    Region: "BAHRAIN",
    "Currency Symbol": ".د.ب",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
  },
  {
    "Currency Code": "BIF",
    "Currency Name": "Burundi Franc",
    Region: "BURUNDI",
    "Currency Symbol": "FBu",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
  },
  {
    "Currency Code": "BMD",
    "Currency Name": "Bermudian Dollar",
    Region: "BERMUDA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
  },
  {
    "Currency Code": "BND",
    "Currency Name": "Brunei Dollar",
    Region: "BRUNEI DARUSSALAM",
    "Currency Symbol": "$",
  },
  {
    "Currency Code": "BOB",
    "Currency Name": "Boliviano",
    Region: "BOLIVIA",
    "Currency Symbol": "$b",
  },
  {
    "Currency Code": "BRL",
    "Currency Name": "Brazilian Real",
    Region: "BRAZIL",
    "Currency Symbol": "R$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
  },
  {
    "Currency Code": "BSD",
    "Currency Name": "Bahamian Dollar",
    Region: "BAHAMAS (THE)",
    "Currency Symbol": "$",
  },
  {
    "Currency Code": "BTN",
    "Currency Name": "Bhutanese Ngultrum",
    Region: "BHUTAN",
    "Currency Symbol": "Nu.",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
  },
  {
    "Currency Code": "BWP",
    "Currency Name": "Botswanan Pula",
    Region: "BOTSWANA",
    "Currency Symbol": "P",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
  },
  {
    "Currency Code": "BYR",
    "Currency Name": "Belarussian Ruble",
    Region: "BELARUS",
    "Currency Symbol": "Br",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
  },
  {
    "Currency Code": "BZD",
    "Currency Name": "Belize Dollar",
    Region: "BELIZE",
    "Currency Symbol": "BZ$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
  },
  {
    "Currency Code": "CAD",
    "Currency Name": "Canadian Dollar",
    Region: "CANADA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
    popular: "true",
  },
  {
    "Currency Code": "CDF",
    "Currency Name": "Congolese Franc",
    Region: "CONGO",
    "Currency Symbol": "FC",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
  },
  {
    "Currency Code": "CHF",
    "Currency Name": "Swiss Franc",
    Region: "SWITZERLAND",
    "Currency Symbol": "CHF",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
  },
  {
    "Currency Code": "CLP",
    "Currency Name": "Chilean Peso",
    Region: "CHILE",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
  },
  {
    "Currency Code": "CNY",
    "Currency Name": "Yuan Renminbi",
    Region: "CHINA",
    "Currency Symbol": "¥",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
  },
  {
    "Currency Code": "COP",
    "Currency Name": "Colombian Peso",
    Region: "COLOMBIA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
  },
  {
    "Currency Code": "CRC",
    "Currency Name": "Costa Rican Colon",
    Region: "COSTA RICA",
    "Currency Symbol": "₡",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
  },
  {
    "Currency Code": "CUP",
    "Currency Name": "Cuban Peso",
    Region: "CUBA",
    "Currency Symbol": "₱",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
  },
  {
    "Currency Code": "CVE",
    "Currency Name": "Cabo Verde Escudo",
    Region: "CABO VERDE",
    "Currency Symbol": "$",
  },
  {
    "Currency Code": "CZK",
    "Currency Name": "Czech Koruna",
    Region: "CZECH REPUBLIC (THE)",
    "Currency Symbol": "Kč",
  },
  {
    "Currency Code": "DJF",
    "Currency Name": "Djibouti Franc",
    Region: "DJIBOUTI",
    "Currency Symbol": "Fdj",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
  },
  {
    "Currency Code": "DKK",
    "Currency Name": "Danish Krone",
    Region: "DENMARK",
    "Currency Symbol": "kr",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
  },
  {
    "Currency Code": "DOP",
    "Currency Name": "Dominican Peso",
    Region: "DOMINICAN REPUBLIC (THE)",
    "Currency Symbol": "RD$",
  },
  {
    "Currency Code": "DZD",
    "Currency Name": "Algerian Dinar",
    Region: "ALGERIA",
    "Currency Symbol": "دج",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
  },
  {
    "Currency Code": "EGP",
    "Currency Name": "Egyptian Pound",
    Region: "EGYPT",
    "Currency Symbol": "£",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
  },
  {
    "Currency Code": "ERN",
    "Currency Name": "Eritrean Nakfa",
    Region: "ERITREA",
    "Currency Symbol": "Nfk",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
  },
  {
    "Currency Code": "ETB",
    "Currency Name": "Ethiopian Birr",
    Region: "ETHIOPIA",
    "Currency Symbol": "Br",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
  },
  {
    "Currency Code": "EUR",
    "Currency Name": "Euro",
    Region: "EUROPEAN UNION",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg",
    "Currency Symbol": "€",
    popular: "true",
  },
  {
    "Currency Code": "FJD",
    "Currency Name": "Fiji Dollar",
    Region: "FIJI",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
  },
  {
    "Currency Code": "FKP",
    "Currency Name": "Falkland Islands Pound",
    Region: "FALKLAND ISLANDS",
    "Currency Symbol": "£",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
  },
  {
    "Currency Code": "GBP",
    "Currency Name": "Pound Sterling",
    Region: "GUERNSEY",
    "Currency Symbol": "£",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
    popular: "true",
  },
  {
    "Currency Code": "GEL",
    "Currency Name": "Georgian Lari",
    Region: "GEORGIA",
    "Currency Symbol": "₾",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
  },
  {
    "Currency Code": "GHS",
    "Currency Name": "Ghana Cedi",
    Region: "GHANA",
    "Currency Symbol": "GH₵",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
  },
  {
    "Currency Code": "GIP",
    "Currency Name": "Gibraltar Pound",
    Region: "GIBRALTAR",
    "Currency Symbol": "£",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
  },
  {
    "Currency Code": "GMD",
    "Currency Name": "Gambian Dalasi",
    Region: "GAMBIA (THE)",
    "Currency Symbol": "D",
  },
  {
    "Currency Code": "GNF",
    "Currency Name": "Guinea Franc",
    Region: "GUINEA",
    "Currency Symbol": "FG",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
  },
  {
    "Currency Code": "GTQ",
    "Currency Name": "Guatemalan Quetzal",
    Region: "GUATEMALA",
    "Currency Symbol": "Q",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
  },
  {
    "Currency Code": "GYD",
    "Currency Name": "Guyana Dollar",
    Region: "GUYANA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
  },
  {
    "Currency Code": "HKD",
    "Currency Name": "Hong Kong Dollar",
    Region: "HONG KONG",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
  },
  {
    "Currency Code": "HNL",
    "Currency Name": "Honduran Lempira",
    Region: "HONDURAS",
    "Currency Symbol": "L",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/ca/Naval_Ensign_of_Honduras.svg",
  },
  {
    "Currency Code": "HRK",
    "Currency Name": "Croatian Kuna",
    Region: "CROATIA",
    "Currency Symbol": "kn",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
  },
  {
    "Currency Code": "HTG",
    "Currency Name": "Haitian Gourde",
    Region: "HAITI",
    "Currency Symbol": "G",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
  },
  {
    "Currency Code": "HUF",
    "Currency Name": "Hungarian Forint",
    Region: "HUNGARY",
    "Currency Symbol": "Ft",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
  },
  {
    "Currency Code": "IDR",
    "Currency Name": "Indonesian Rupiah",
    Region: "INDONESIA",
    "Currency Symbol": "Rp",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
  },
  {
    "Currency Code": "ILS",
    "Currency Name": "New Israeli Sheqel",
    Region: "ISRAEL",
    "Currency Symbol": "₪",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
  },
  {
    "Currency Code": "INR",
    "Currency Name": "Indian Rupee",
    Region: "INDIA",
    "Currency Symbol": "₹",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
    popular: "true",
  },
  {
    "Currency Code": "IQD",
    "Currency Name": "Iraqi Dinar",
    Region: "IRAQ",
    "Currency Symbol": "ع.د",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
  },
  {
    "Currency Code": "IRR",
    "Currency Name": "Iranian Rial",
    Region: "IRAN (ISLAMIC REPUBLIC OF)",
    "Currency Symbol": "﷼",
  },
  {
    "Currency Code": "ISK",
    "Currency Name": "Iceland Krona",
    Region: "ICELAND",
    "Currency Symbol": "kr",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
  },
  {
    "Currency Code": "JMD",
    "Currency Name": "Jamaican Dollar",
    Region: "JAMAICA",
    "Currency Symbol": "J$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
  },
  {
    "Currency Code": "JOD",
    "Currency Name": "Jordanian Dinar",
    Region: "JORDAN",
    "Currency Symbol": "JD",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
  },
  {
    "Currency Code": "JPY",
    "Currency Name": "Japanese Yen",
    Region: "JAPAN",
    "Currency Symbol": "¥",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
    popular: "true",
  },
  {
    "Currency Code": "KES",
    "Currency Name": "Kenyan Shilling",
    Region: "KENYA",
    "Currency Symbol": "KSh",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
  },
  {
    "Currency Code": "KGS",
    "Currency Name": "Kyrgystani Som",
    Region: "KYRGYZSTAN",
    "Currency Symbol": "лв",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
  },
  {
    "Currency Code": "KHR",
    "Currency Name": "Cambodian Riel",
    Region: "CAMBODIA",
    "Currency Symbol": "៛",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
  },
  {
    "Currency Code": "KMF",
    "Currency Name": "Comoro Franc",
    Region: "COMOROS (THE)",
    "Currency Symbol": "CF",
  },
  {
    "Currency Code": "KPW",
    "Currency Name": "North Korean Won",
    Region: "KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)",
    "Currency Symbol": "₩",
  },
  {
    "Currency Code": "KRW",
    "Currency Name": "South Korean Won",
    Region: "KOREA (THE REPUBLIC OF)",
    "Currency Symbol": "₩",
  },
  {
    "Currency Code": "KWD",
    "Currency Name": "Kuwaiti Dinar",
    Region: "KUWAIT",
    "Currency Symbol": "KD",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
  },
  {
    "Currency Code": "KYD",
    "Currency Name": "Cayman Islands Dollar",
    Region: "CAYMAN ISLANDS (THE)",
    "Currency Symbol": "$",
  },
  {
    "Currency Code": "KZT",
    "Currency Name": "Kazakhstani Tenge",
    Region: "KAZAKHSTAN",
    "Currency Symbol": "₸",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
  },
  {
    "Currency Code": "LAK",
    "Currency Name": "Laotian Kip",
    Region: "LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)",
    "Currency Symbol": "₭",
  },
  {
    "Currency Code": "LBP",
    "Currency Name": "Lebanese Pound",
    Region: "LEBANON",
    "Currency Symbol": "£",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
  },
  {
    "Currency Code": "LKR",
    "Currency Name": "Sri Lanka Rupee",
    Region: "SRI LANKA",
    "Currency Symbol": "₨",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
  },
  {
    "Currency Code": "LRD",
    "Currency Name": "Liberian Dollar",
    Region: "LIBERIA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
  },
  {
    "Currency Code": "LSL",
    "Currency Name": "Lesotho Loti",
    Region: "LESOTHO",
    "Currency Symbol": "M",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
  },
  {
    "Currency Code": "LYD",
    "Currency Name": "Libyan Dinar",
    Region: "LIBYA",
    "Currency Symbol": "LD",
  },
  {
    "Currency Code": "MAD",
    "Currency Name": "Moroccan Dirham",
    Region: "MOROCCO",
    "Currency Symbol": "MAD",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
  },
  {
    "Currency Code": "MDL",
    "Currency Name": "Moldovan Leu",
    Region: "MOLDOVA (THE REPUBLIC OF)",
    "Currency Symbol": "lei",
  },
  {
    "Currency Code": "MGA",
    "Currency Name": "Malagasy Ariary",
    Region: "MADAGASCAR",
    "Currency Symbol": "Ar",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
  },
  {
    "Currency Code": "MKD",
    "Currency Name": "Macedonian Denar",
    Region: "MACEDONIA",
    "Currency Symbol": "ден",
  },
  {
    "Currency Code": "MMK",
    "Currency Name": "Myanma Kyat",
    Region: "MYANMAR",
    "Currency Symbol": "K",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
  },
  {
    "Currency Code": "MNT",
    "Currency Name": "Mongolian Tugrik",
    Region: "MONGOLIA",
    "Currency Symbol": "₮",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
  },
  {
    "Currency Code": "MOP",
    "Currency Name": "Macanese Pataca",
    Region: "MACAO",
    "Currency Symbol": "MOP$",
  },
  {
    "Currency Code": "MUR",
    "Currency Name": "Mauritius Rupee",
    Region: "MAURITIUS",
    "Currency Symbol": "₨",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
  },
  {
    "Currency Code": "MVR",
    "Currency Name": "Maldivian Rufiyaa",
    Region: "MALDIVES",
    "Currency Symbol": "Rf",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
  },
  {
    "Currency Code": "MWK",
    "Currency Name": "Malawian Kwacha",
    Region: "MALAWI",
    "Currency Symbol": "MK",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
  },
  {
    "Currency Code": "MXN",
    "Currency Name": "Mexican Peso",
    Region: "MEXICO",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
  },
  {
    "Currency Code": "MYR",
    "Currency Name": "Malaysian Ringgit",
    Region: "MALAYSIA",
    "Currency Symbol": "RM",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
  },
  {
    "Currency Code": "MZN",
    "Currency Name": "Mozambique Metical",
    Region: "MOZAMBIQUE",
    "Currency Symbol": "MT",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
  },
  {
    "Currency Code": "NAD",
    "Currency Name": "Namibia Dollar",
    Region: "NAMIBIA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
  },
  {
    "Currency Code": "NGN",
    "Currency Name": "Nigerian Naira",
    Region: "NIGERIA",
    "Currency Symbol": "₦",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
  },
  {
    "Currency Code": "NIO",
    "Currency Name": "Cordoba Oro",
    Region: "NICARAGUA",
    "Currency Symbol": "C$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
  },
  {
    "Currency Code": "NOK",
    "Currency Name": "Norwegian Krone",
    Region: "NORWAY",
    "Currency Symbol": "kr",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
  },
  {
    "Currency Code": "NPR",
    "Currency Name": "Nepalese Rupee",
    Region: "NEPAL",
    "Currency Symbol": "₨",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
  },
  {
    "Currency Code": "NZD",
    "Currency Name": "New Zealand Dollar",
    Region: "NEW ZEALAND",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
  },
  {
    "Currency Code": "OMR",
    "Currency Name": "Rial Omani",
    Region: "OMAN",
    "Currency Symbol": "﷼",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
  },
  {
    "Currency Code": "PAB",
    "Currency Name": "Panamanian Balboa",
    Region: "PANAMA",
    "Currency Symbol": "B/.",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
  },
  {
    "Currency Code": "PEN",
    "Currency Name": "Peruvian Nuevo Sol",
    Region: "PERU",
    "Currency Symbol": "S/.",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
  },
  {
    "Currency Code": "PGK",
    "Currency Name": "Papua Kina",
    Region: "PAPUA NEW GUINEA",
    "Currency Symbol": "K",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
  },
  {
    "Currency Code": "PHP",
    "Currency Name": "Philippine Peso",
    Region: "PHILIPPINES (THE)",
    "Currency Symbol": "₱",
  },
  {
    "Currency Code": "PKR",
    "Currency Name": "Pakistan Rupee",
    Region: "PAKISTAN",
    "Currency Symbol": "₨",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
  },
  {
    "Currency Code": "PLN",
    "Currency Name": "Polish Zloty",
    Region: "POLAND",
    "Currency Symbol": "zł",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
  },
  {
    "Currency Code": "PYG",
    "Currency Name": "Paraguayan Guarani",
    Region: "PARAGUAY",
    "Currency Symbol": "Gs",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
  },
  {
    "Currency Code": "QAR",
    "Currency Name": "Qatari Rial",
    Region: "QATAR",
    "Currency Symbol": "﷼",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
  },
  {
    "Currency Code": "RON",
    "Currency Name": "Romanian Leu",
    Region: "ROMANIA",
    "Currency Symbol": "lei",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
  },
  {
    "Currency Code": "RSD",
    "Currency Name": "Serbian Dinar",
    Region: "SERBIA",
    "Currency Symbol": "Дин.",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
  },
  {
    "Currency Code": "RUB",
    "Currency Name": "Russian Ruble",
    Region: "RUSSIAN FEDERATION (THE)",
    "Currency Symbol": "₽",
  },
  {
    "Currency Code": "RWF",
    "Currency Name": "Rwanda Franc",
    Region: "RWANDA",
    "Currency Symbol": "R₣",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
  },
  {
    "Currency Code": "SAR",
    "Currency Name": "Saudi Riyal",
    Region: "SAUDI ARABIA",
    "Currency Symbol": "﷼",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
  },
  {
    "Currency Code": "SBD",
    "Currency Name": "Solomon Islands Dollar",
    Region: "SOLOMON ISLANDS",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
  },
  {
    "Currency Code": "SCR",
    "Currency Name": "Seychelles Rupee",
    Region: "SEYCHELLES",
    "Currency Symbol": "₨",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
  },
  {
    "Currency Code": "SDG",
    "Currency Name": "Sudanese Pound",
    Region: "SUDAN (THE)",
    "Currency Symbol": "ج.س.",
  },
  {
    "Currency Code": "SEK",
    "Currency Name": "Swedish Krona",
    Region: "SWEDEN",
    "Currency Symbol": "kr",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
  },
  {
    "Currency Code": "SGD",
    "Currency Name": "Singapore Dollar",
    Region: "SINGAPORE",
    "Currency Symbol": "S$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
  },
  {
    "Currency Code": "SHP",
    "Currency Name": "Saint Helena Pound",
    Region: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    "Currency Symbol": "£",
  },
  {
    "Currency Code": "SLL",
    "Currency Name": "Leone",
    Region: "SIERRA LEONE",
    "Currency Symbol": "Le",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
  },
  {
    "Currency Code": "SOS",
    "Currency Name": "Somali Shilling",
    Region: "SOMALIA",
    "Currency Symbol": "S",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
  },
  {
    "Currency Code": "SRD",
    "Currency Name": "Surinam Dollar",
    Region: "SURINAME",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
  },
  {
    "Currency Code": "SVC",
    "Currency Name": "El Salvador Colon",
    Region: "EL SALVADOR",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
  },
  {
    "Currency Code": "SYP",
    "Currency Name": "Syrian Pound",
    Region: "SYRIAN ARAB REPUBLIC",
    "Currency Symbol": "£",
  },
  {
    "Currency Code": "SZL",
    "Currency Name": "Swazi Lilangeni",
    Region: "SWAZILAND",
    "Currency Symbol": "E",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg",
  },
  {
    "Currency Code": "THB",
    "Currency Name": "Thai Baht",
    Region: "THAILAND",
    "Currency Symbol": "฿",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
  },
  {
    "Currency Code": "TJS",
    "Currency Name": "Tajikistani Somoni",
    Region: "TAJIKISTAN",
    "Currency Symbol": "SM",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
  },
  {
    "Currency Code": "TMT",
    "Currency Name": "Turkmenistan New Manat",
    Region: "TURKMENISTAN",
    "Currency Symbol": "T",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
  },
  {
    "Currency Code": "TND",
    "Currency Name": "Tunisian Dinar",
    Region: "TUNISIA",
    "Currency Symbol": "د.ت",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
  },
  {
    "Currency Code": "TOP",
    "Currency Name": "Tongan Paʻanga",
    Region: "TONGA",
    "Currency Symbol": "T$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
  },
  {
    "Currency Code": "TRY",
    "Currency Name": "Turkish Lira",
    Region: "TURKEY",
    "Currency Symbol": "₺",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    "Currency Code": "TTD",
    "Currency Name": "Trinidad and Tobago Dollar",
    Region: "TRINIDAD AND TOBAGO",
    "Currency Symbol": "TT$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
  },
  {
    "Currency Code": "TWD",
    "Currency Name": "New Taiwan Dollar",
    Region: "TAIWAN (PROVINCE OF CHINA)",
    "Currency Symbol": "NT$",
  },
  {
    "Currency Code": "TZS",
    "Currency Name": "Tanzanian Shilling",
    Region: "TANZANIA, UNITED REPUBLIC OF",
    "Currency Symbol": "TSh",
  },
  {
    "Currency Code": "UAH",
    "Currency Name": "Ukrainian Hryvnia",
    Region: "UKRAINE",
    "Currency Symbol": "₴",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
  },
  {
    "Currency Code": "UGX",
    "Currency Name": "Uganda Shilling",
    Region: "UGANDA",
    "Currency Symbol": "USh",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
  },
  {
    "Currency Code": "USD",
    "Currency Name": "US Dollar",
    Region: "UNITED STATES OF AMERICA",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
    popular: "true",
  },
  {
    "Currency Code": "UYU",
    "Currency Name": "Peso Uruguayo",
    Region: "URUGUAY",
    "Currency Symbol": "$U",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
  },
  {
    "Currency Code": "UZS",
    "Currency Name": "Uzbekistan Sum",
    Region: "UZBEKISTAN",
    "Currency Symbol": "лв",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
  },
  {
    "Currency Code": "VEF",
    "Currency Name": "Venezuelan Bolivar",
    Region: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    "Currency Symbol": "Bs",
  },
  {
    "Currency Code": "VND",
    "Currency Name": "Vietnamese Dong",
    Region: "VIET NAM",
    "Currency Symbol": "₫",
  },
  {
    "Currency Code": "VUV",
    "Currency Name": "Vanuatu Vatu",
    Region: "VANUATU",
    "Currency Symbol": "VT",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
  },
  {
    "Currency Code": "WST",
    "Currency Name": "Samoan Tala",
    Region: "SAMOA",
    "Currency Symbol": "WS$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
  },
  {
    "Currency Code": "XAF",
    "Currency Name": "CFA Franc BEAC",
    Region: "CENTRAL AFRICAN",
    "Currency Symbol": "FCFA",
  },
  {
    "Currency Code": "XCD",
    "Currency Name": "East Caribbean Dollar",
    Region: "EASTERN CARIBBEAN STATES",
    "Currency Symbol": "$",
  },
  {
    "Currency Code": "XDR",
    "Currency Name": "SDR (Special Drawing Right)",
    Region: "INTERNATIONAL MONETARY FUND (IMF)",
  },
  {
    "Currency Code": "XOF",
    "Currency Name": "CFA Franc BCEAO",
    Region: "WEST AFRICAN",
    "Currency Symbol": "CFA",
  },
  {
    "Currency Code": "XPF",
    "Currency Name": "CFP Franc",
    Region: "FRENCH COLONIES OF THE PACIFIC",
    "Currency Symbol": "₣",
  },
  {
    "Currency Code": "YER",
    "Currency Name": "Yemeni Rial",
    Region: "YEMEN",
    "Currency Symbol": "﷼",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
  },
  {
    "Currency Code": "ZAR",
    "Currency Name": "South African Rand",
    Region: "SOUTH AFRICA",
    "Currency Symbol": "R",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
  },
  {
    "Currency Code": "ZMW",
    "Currency Name": "Zambian Kwacha",
    Region: "ZAMBIA",
    "Currency Symbol": "ZK",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
  },
  {
    "Currency Code": "ZWL",
    "Currency Name": "Zimbabwe Dollar",
    Region: "ZIMBABWE",
    "Currency Symbol": "$",
    "Country Flag":
      "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
  },
];
