
const REACT_APP_SAFLE_AI_API = process.env.REACT_APP_SAFLE_AI_API

export const fetchQueryResponse = async (messages) => {
    const response = await fetch(
        `${REACT_APP_SAFLE_AI_API}/v1/query`, 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages }),
        }
    );

    if (!response.ok) {
        const errorMsg = await response.json()
        throw new Error(errorMsg?.error?.message);
    }

    return response.body; // Return the ReadableStream
};